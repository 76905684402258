<template>
  <BaseModal
    name="Modal-New-Page"
    id="Modal-New-Page"
    idModal="Modal-New-Page"
    size="md"
    title="Criar nova página"
    @shown="limparCampos()"
  >
    <b-tabs
      nav-class="TabHeaderCreatePage"
      active-nav-item-class="TabHeader--active"
    >
      <b-tab
        title="Nova página"
        active
        @click="typeList = 'Create'"
      >
        <label for="newPageName">{{dynamicTetx}}</label>
        <input class="w-100" type="text" v-model="pageName">
      </b-tab>
      <b-tab
        v-if="template"
        title="Copiar página"
        @click="typeList = 'Coṕy'"
      >
      <label for="newPageLink">Link da página para clonar</label>
      <input class="w-100" type="text" v-model="pageLink" placeholder="sua-pagina.gdigital.com.br">
    </b-tab>
  </b-tabs>
  <template v-slot:footer="{ cancel }">
    <BaseButton variant="link-info" class="mr-4" @click="cancel">
      Cancelar
    </BaseButton>
    <BaseButton 
      :class="loading ? 'shine' : ''" 
      variant="primary" 
      :disabled="loading" 
      @click="typeList === 'Create' ? onSubmit() : onCopy()"
    >
      Criar
    </BaseButton>
  </template>

  </BaseModal>
</template>

<script>
import PageService from "@/services/resources/PageService";
import CopyPageService from "@/services/resources/CopyPageService";
const servicePage = PageService.build();
import pageBasec from "@/components/Editor/Components/NewPage.js";

import TemplateService from "@/services/resources/TemplateService";
const serviceTemplate = TemplateService.build();

const serviceCopyPage = CopyPageService.build();
export default {
  props: {
    template: {
      type: Boolean,
      default: false 
    },
  },
  data() {
    return {
      pageName: '',
      pageLink: '',
      typeList: 'Create',
      loading: false,
    };
  },
  methods: {
    limparCampos(){
      this.pageName = ''
      this.pageLink = ''
    },
    async apiSetHistoryPage(id) {
      var data = {
        id: `/save-historic`,
        historic: pageBasec,
        page_id: id,
        template_id: id
      };
      if(this.template){
        delete data.page_id
        await serviceTemplate
        .createId(data).then(()=>{
          this.$router.push(`editor/${id}`)
          this.loading = false;
        })
      }else{
        delete data.template_id
        await servicePage
        .createId(data).then(()=>{
          this.loading = false;
        }
      )
      }
    },
    removeAccents(str) {
      str = str
        .toLowerCase()
        .replace(/[áàãâä]/gi, "a")
        .replace(/[éèêë]/gi, "e")
        .replace(/[íìîï]/gi, "i")
        .replace(/[óòõôö]/gi, "o")
        .replace(/[úùûü]/gi, "u")
        .replace(/[ç]/gi, "c")
        .replace(/[ñ]/gi, "n")
        .replace(/\s+/g, "-");
      str = str.replace(/(?!-)[^a-z0-9]/gi, "");
      str = str.replace(/-+/gi, "-");
      str = str.replace(/  ?/gi, "");
      return str;
    },
    onSubmit() {
      if (!this.pageName) {
        this.$grToast.toast("Adicione um nome à página", {
          title: "Páginas",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }

      const regex = /  /;
      if (regex.test(this.pageName)) {
        return;
      }
      this.loading = true;
      let data = {
        title: this.pageName,
        status: "publish",
        json: pageBasec,
        path_name: this.removeAccents(this.pageName),
        campaign_id: this.$route.params.id,
      };
      if(this.template){
        data.status = 'draft'
        delete data.campaign_id
        serviceTemplate
        .create(data)
        .then(async (resp) => {
          this.$emit("newPage", resp.id);
          await this.apiSetHistoryPage(resp.id)
          this.$bvModal.hide('Modal-New-Page');
        })
        .catch((err) => {console.error(err)})
        .finally(() => {
        });
      }else{
        servicePage
          .create(data)
          .then(async (resp) => {
            this.$emit("newPage", resp.page.id);
            await this.apiSetHistoryPage(resp.page.id)
            this.$bvModal.hide('Modal-New-Page');
          })
          .catch((err) => {console.error(err)})
          .finally(() => {
          });
      }
    }
  },
  computed: {
    dynamicTetx() {
      return `${this.template ? 'Nome do template' : 'Nome da nova página'}`
    },
    dynamicTitle(){
      return `${this.template ? 'Criar novo template' : 'Criar nova página'}`
    }
  },
    onCopy(){
      if (!this.pageLink) {
        this.$grToast.toast("Adicione o link da página", {
          title: "Páginas",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      if (!this.pageLink.includes('.gdigital.com.br') && !this.pageLink.includes('.greennsales.com.br')) {
        this.$grToast.toast("O link inserido não é válido", {
          title: "Páginas",
          variant: "danger",
          autoHideDelay: 300,
          appendToast: true,
        });
        return;
      }
      const url = new URL(this.pageLink)
      const dataCopy = {
        hostname: url.hostname,
        pathname: url.pathname.substring(1),
      };
      this.loading = true
      
      serviceCopyPage
        .create(dataCopy)
        .then((result) => {
          let metasObj = {}
          for (let i = 0; i < result.metas.length; i++ ){
            if(result.metas[i].meta_key === "font_family" || result.metas[i].meta_key === "palette" || result.metas[i].meta_key === "dynamic_css" || result.metas[i].meta_key === "dynamic_js" || result.metas[i].meta_key === "overlap"){
              metasObj[result.metas[i].meta_key] = result.metas[i].meta_value
            }
          }
          let data = {
            title: result.title,
            status: "publish",
            json: result.json,
            metas: metasObj,
            path_name: (result.path_name),
            campaign_id: this.$route.params.id,
          };
          for (let a = 0; a < result.json.length; a++){
            for (let b = 0; b < result.json[a].length; b++){
              for (let c = 0; c < result.json[a][b].coluns.length; c++){
                for (let d = 0; d < result.json[a][b].coluns[c].length; d++){
                  if (result.json[a][b].coluns[c][d].type === 'Form' || result.json[a][b].coluns[c][d].type === 'PopUp' || result.json[a][b].coluns[c][d].type === 'StepsForm'){
                    result.json[a][b].coluns[c][d].propsData.form = {}
                    result.json[a][b].coluns[c][d].propsData.fields = []
                    result.json[a][b].coluns[c][d].propsData.page_id = 0
                    result.json[a][b].coluns[c][d].propsData.tenant_id = 0
                    result.json[a][b].coluns[c][d].propsData.form_id = 0
                    result.json[a][b].coluns[c][d].propsData.thank_you_page = {}
                    result.json[a][b].coluns[c][d].propsData.phone = ""
                  }
                }
              }
            }
          }
          servicePage
            .create(data)
            .then((resp) => {
              this.$emit("newPage", resp.page.id);
              this.$bvModal.hide('Modal-New-Page');
            })
            .finally(() => {
              this.loading = false;
            })
          })
        .catch((error) => {
          this.$grToast.toast(error.response.data.error , {
            title: "Páginas",
            variant: "danger",
            autoHideDelay: 3000,
            appendToast: true,
          });
          this.loading = false;
        })
      },
}
</script>
<style lang="scss">
#Modal-New-Page {
  .tab-content > .tab-pane {
    border: 1px solid var(--white-medium) !important;
    border-radius: 0 10px 10px;
    padding: 20px;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #009488 !important;
    font-weight: 500 !important;
  }
  .nav-tabs {
    font-weight: 500 !important;
    border-bottom: none !important;
  }
  .TabHeaderCreatePage .nav-item a {
    padding: 10px 15px !important;
    color: var(--gray05);
    width: fit-content !important;
    border-radius: 0;
    border: 1px solid var(--white-medium) !important;
    border-right: none !important;
    transition: all 0.3s;
    font-weight: 500 !important;
    &:hover {
      color: var(--gray05);
    }
  }
  .TabHeaderCreatePage .nav-item a.active {
    border-bottom-color: var(--white) !important;
    background-color: var(--white);
  }

  .TabHeaderCreatePage .nav-tabs .nav-link:hover {
    border: 1px solid var(--white-medium) !important;
  }
  .TabHeaderCreatePage .nav-item a[aria-posinset="1"] {
    border-radius: 10px 0 0 0 !important;
  }

  .TabHeaderCreatePage .nav-item a[aria-posinset="2"] {
    border-radius: 0 10px 0 0 !important;
    border-right: 1px solid var(--white-medium) !important;
  }
}
</style>